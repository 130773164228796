<template>
  <div class="page-works-detail">
    <Loading v-if="loading" :count="6"></Loading>
    <el-descriptions v-else :column="1" :colon="false">
      <el-descriptions-item>
        <div
          style="
            margin-left: -100px;
            color: #ffc800;
            font-size: 16px;
            font-weight: 600;
          "
        >
          ⌷ 基础信息
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="所属竞赛">
        {{ detail.jsName || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="所属赛道">
        {{ detail.trackName || '-' }}
      </el-descriptions-item>
      <el-descriptions-item :label="$t('entries.name')">
        {{ detail.name || '-' }}
      </el-descriptions-item>
      <template v-if="detail.type === 1">
        <el-descriptions-item>
          <div
            style="
              margin-left: -100px;
              color: #ffc800;
              font-size: 16px;
              font-weight: 600;
            "
          >
            ⌷ 作品信息
          </div>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('entries.thumbnail')">
          <el-image
            v-if="detail.thumbnail"
            style="width: 100px; height: 100px"
            :src="detail.thumbnail"
            :preview-src-list="[detail.thumbnail]"
          ></el-image>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('entries.title')">
          {{ detail.title || '-' }}
        </el-descriptions-item>
        <el-descriptions-item :label="$t('entries.faceimg')">
          <el-image
            v-if="detail.faceImg"
            style="width: 100px; height: 100px"
            :src="detail.faceImg"
            :preview-src-list="[detail.faceImg]"
          ></el-image>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('entries.imgdetail')">
          <!-- <template v-if="detail.detailImg">
          <el-image
            v-for="(item, i) in detail.detailImg.split(',')"
            :key="i"
            style="width: 100px; height: 100px; margin-right: 10px"
            :src="item"
            :preview-src-list="detail.detailImg.split(',')"
          ></el-image>
        </template> -->
          <el-image
            v-if="detail.detailImg"
            style="width: 100px; height: 100px"
            :src="detail.detailImg"
            :preview-src-list="[detail.detailImg]"
          ></el-image>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('entries.imgdetail')">
          <!-- <template v-if="detail.appendFile1">
          <img
            style="width: 60px; height: 60px"
            src="@/assets/images/zip.png"
          />
          <br />
          <el-button type="text" @click="download(detail.appendFile1)">
            {{ getFileName(detail.appendFile1) }}下载
          </el-button>
        </template> -->
          <el-image
            v-if="detail.appendFile1"
            style="width: 100px; height: 100px"
            :src="detail.appendFile1"
            :preview-src-list="[detail.appendFile1]"
          ></el-image>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('entries.imgdetail')">
          <!-- <template v-if="detail.appendFile2">
          <img
            style="width: 60px; height: 60px"
            src="@/assets/images/zip.png"
          />
          <br />
          <el-button type="text" @click="download(detail.appendFile2)">
            {{ getFileName(detail.appendFile2) }}下载
          </el-button>
        </template> -->
          <el-image
            v-if="detail.appendFile2"
            style="width: 100px; height: 100px"
            :src="detail.appendFile2"
            :preview-src-list="[detail.appendFile2]"
          ></el-image>
          <span v-else>-</span>
        </el-descriptions-item>
      </template>
      <template v-if="detail.type === 2">
        <el-descriptions-item :label="$t('entries.thumbnail')">
          <el-image
            v-if="detail.thumbnail"
            style="width: 100px; height: 100px"
            :src="detail.thumbnail"
            :preview-src-list="[detail.thumbnail]"
          ></el-image>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item :label="$t('entries.faceimg')">
          <el-image
            v-if="detail.faceImg"
            style="width: 100px; height: 100px"
            :src="detail.faceImg"
            :preview-src-list="[detail.faceImg]"
          ></el-image>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="说明图">
          <template v-if="detail.detailImg">
            <el-image
              v-for="(item, i) in detail.detailImg.split(',')"
              :key="i"
              style="width: 100px; height: 100px; margin-left: 10px"
              :src="item"
              :preview-src-list="detail.detailImg.split(',')"
            ></el-image>
          </template>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <div
            style="
              margin-left: -100px;
              color: #ffc800;
              font-size: 16px;
              font-weight: 600;
            "
          >
            ⌷ 作品介绍
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="作品定位">
          {{ detail.opusFix || '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="创新点">
          {{ detail.innovate || '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="市场定位">
          {{ detail.marketFix || '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="核心技术">
          {{ detail.coreTechnology || '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="生产工艺">
          {{ detail.productionProcess || '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="未来前景">
          {{ detail.futureProspects || '-' }}
        </el-descriptions-item>
        <el-descriptions-item>
          <div
            style="
              margin-left: -100px;
              color: #ffc800;
              font-size: 16px;
              font-weight: 600;
            "
          >
            ⌷ 技术文档
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="3D模型">
          <a v-if="detail.model" :href="detail.model" download target="_blank">
            下载
          </a>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="结构文档">
          <a
            v-if="detail.structureDocument"
            :href="detail.structureDocument"
            download
            target="_blank"
          >
            下载
          </a>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="作品视频">
          <a
            v-if="detail.opusVideo"
            :href="detail.opusVideo"
            download
            target="_blank"
          >
            下载
          </a>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item label="其他材料">
          <a
            v-if="detail.otherFile"
            :href="detail.otherFile"
            download
            target="_blank"
          >
            下载
          </a>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <div
            style="
              margin-left: -100px;
              color: #ffc800;
              font-size: 16px;
              font-weight: 600;
            "
          >
            ⌷ 专利情况
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="专利情况">
          <span v-if="detail.patentStatus === 1">已申请</span>
          <span v-if="detail.patentStatus === 2">申请中</span>
          <span v-if="detail.patentStatus === 3">未申请</span>
        </el-descriptions-item>
        <el-descriptions-item
          label="专利类型"
          v-if="detail.patentStatus === 1 && detail.patentType"
        >
          <span v-for="item in detail.patentType.split(',')" :key="item">
            {{ patentTypeMap[item] }}
          </span>
        </el-descriptions-item>
        <el-descriptions-item
          label="证明材料"
          v-if="[1, 2].includes(detail.patentStatus)"
        >
          <a
            v-if="detail.proveFile"
            :href="detail.proveFile"
            download
            target="_blank"
          >
            下载
          </a>
          <span v-else>-</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <div
            style="
              margin-left: -100px;
              color: #ffc800;
              font-size: 16px;
              font-weight: 600;
            "
          >
            ⌷ 运营情况
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="上市情况">
          <span v-if="detail.onMarket">已上市</span>
          <span v-else>未上市</span>
        </el-descriptions-item>
        <template v-if="detail.onMarket">
          <el-descriptions-item label="上市情况">
            {{ detail.onMarketTime || '-' }}
          </el-descriptions-item>
          <el-descriptions-item label="上市售价">
            {{ detail.price || '-' }}
          </el-descriptions-item>
        </template>
        <template v-else>
          <el-descriptions-item label="预期上市时间">
            {{ detail.onMarketTime || '-' }}
          </el-descriptions-item>
          <el-descriptions-item label="预期上市售价">
            {{ detail.price || '-' }}
          </el-descriptions-item>
        </template>
      </template>
    </el-descriptions>
  </div>
</template>

<script>
import Loading from '@/components/loading'
import { getFileName, saveFile } from '@/utils/tools'

const patentTypeMap = {
  1: '实用专利类型',
  2: '外观设计类型',
  3: '发明专利类型',
  4: '软著/著作类型',
  5: '商标类型'
}

export default {
  components: {
    Loading
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      detail: null,
      patentTypeMap
    }
  },
  mounted() {
    this.fetchDetail()
  },
  methods: {
    getFileName(path) {
      return getFileName(path)
    },
    async fetchDetail() {
      this.loading = true
      const [err, res] = await this.$serve.jsOpus.info({
        queryId: this.id
      })
      this.loading = false
      if (err) return
      this.detail = res
    },
    download(path) {
      saveFile(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-works-detail {
  ::v-deep {
    .el-descriptions-item__container {
      min-height: 40px;
      line-height: 40px;
    }
    .el-descriptions-item__label {
      width: 120px;
      text-align: right;
    }
  }
}
</style>
